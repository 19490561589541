import React, { useEffect, useState } from 'react';
// import { ZoomMtg } from '@zoomus/websdk';
import { ZoomMtg } from '@zoom/meetingsdk';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Lottie from "lottie-react";
import loading from '../img/loading.json'
import { Invalid } from './Invalid';
import "./join.css"
import { AdminState } from "../Context/Context";
import Unauthorised from './Unauthorised';


// ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');
// ZoomMtg.setZoomJSLib('https://source.zoom.us/3.9.2/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function Start_meeting() {
  const [cookies, setCookie] = useCookies(['user']);
  const [event,setEvent]=useState([]);
  const [invalid,setInvalid]=useState(0);
  const [isAuthenticate,setIsauthenticate]=useState(false);
  const navigate=useNavigate();
  const params=useParams();
  const {is_loggedIn,setIs_loggedIn}=AdminState();
  const [searchParams] = useSearchParams();
  const event_id = params.id || searchParams.get('event_id');
    const get_event_details=async()=>{
      const form_data = { 
          user_id:cookies.userId,
          event_id:event_id,    
      };      
      const live_url='https://iasscore.in/api/mentorbox/event_details_for_zoom';
      const local_url='http://localhost/rest_api/mentorbox/event_details';

      axios.post(live_url, form_data,{
        headers: {
          'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>' 
        }
      })
      .then((response) => { 
        console.log(response.data.details)
        if(response.data.details.details){
          setEvent(response.data.details.details) 
          setIsauthenticate(true); 
        }else{
          setInvalid(1);
          console.log(invalid)
        }    
      }, (error) => {
        console.log(error);        
      }); 
    } 
    useEffect(() => {       
      if(is_loggedIn){
        get_event_details();
      }      
    },[is_loggedIn])



    useEffect(() => {  
      if(is_loggedIn){
        start();
        document.title=event.event_title
        console.log(isAuthenticate);
      }    
    }, [event.event_code])

    const start=()=>{     
      if(parseInt(event.faculty_id)==parseInt(cookies.userId) || cookies.userType==0 || cookies.userType==4 || cookies.userId=='896702' || cookies.userType >= 5 ){
        // console.log('event_code',event.event_code);
        if(event.event_code){
          getSignature();  
        }
      }else{
        navigate('access-denied');
      }   
    }
    
  var signatureEndpoint = 'https://conapi.iasscore.in'
  var sdkKey = '88Itp1a0NGJ7VTaVW3ZYpX6VLbCwhAdyD9Di';
  var meetingNumber = event.event_code
  var role = 1
  var leaveUrl = 'https://connect.iasscore.in/thank-you'
  var userName = cookies.userName ? cookies.userName.replaceAll("+", " "): ''
  var userEmail = cookies.userEmail
  var passWord =  event.created_by
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  var registrantToken = ''

  function getSignature() {
    console.log(meetingNumber,role);
    fetch(signatureEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        meetingNumber: meetingNumber,
        role: role
      })
    }).then(res => res.json())
    .then(response => {
      // console.log('response',response);
      startMeeting(response.signature)
    }).catch(error => {
      console.error(error)
    })
  }

  function startMeeting(signature) {
    console.log('signature',signature);
    console.log('meetingNumber',meetingNumber);
    console.log('userName',userName);
    console.log('sdkKey',sdkKey);
    console.log('userEmail',userEmail);
    console.log('passWord',passWord);
    document.getElementById('zmmtg-root').style.display = 'block'
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      disableInvite: true,
      success: (success) => {
        console.log(success)
        ZoomMtg.join({
          signature: signature,
          meetingNumber: meetingNumber,
          userName: userName,
          sdkKey: sdkKey,
          userEmail: userEmail,
          passWord: passWord,
          tk: registrantToken,
          success: (success) => {
            console.log(success)            
          },
          error: (error) => {
            console.log(error)
          }
        })
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
      {!is_loggedIn ? 
        (<Unauthorised/>)
        :
        (
          invalid ? 
            <Invalid/>        
          :
            <div className='loading'>
            <Lottie animationData={loading} loop={true}  style={{width:"30%",margin:"auto"}}/>
            </div>
        )
      }
    </div>
  );
}

export default Start_meeting;
